import React from 'react'
import { Layout } from '../components/layout/Layout'
import { HeadingMd, HeadingSm } from '../components/heading/Heading'
import { TextBanner } from '../components/text-banner/TextBanner'
import { CtaButtons } from '../components/cta-buttons/CtaButtons'
import { Quote } from '../components/quote/Quote'
import { Cards } from '../components/cards/Cards'

const CoachPage = () => {
  return (
    <Layout title="De postpartum coach" desc="De postpartum coach bezoekt de (aanstaande) moeder in de veilige en vertrouwde thuis-/privésituatie.">
      <TextBanner>

        <HeadingMd h={2}>De postpartum coach</HeadingMd>
        <p>De postpartum coach bezoekt de (aanstaande) moeder in de veilige en vertrouwde thuis-/privésituatie. De plek waar ook jouw kind en/of gezin zijn om te interventie zo concreet, dichtbij en direct mogelijk te kunnen verbinden aan de thuissituatie.</p>

        <Quote text="“Sta in je kracht in plaats van in je kwetsbaarheid”" />

        <HeadingSm h={3}>De postpartum coach luistert, stelt vragen en geeft inzichten om je:</HeadingSm>
        <ul>
          <li>Zelfverzekerd te voelen</li>
          <li>Te vertrouwen op je intuïtie</li>
          <li>Inzicht krijgen en benutten waar je goed in bent</li>
          <li>Signalen herkennen om goed voor jezelf te zorgen en (tijdig) grenzen te stellen</li>
          <li>Handvatten om moeilijk situaties aan te kunnen</li>
          <li>Positief te voelen, denken en doen</li>
          <li>Zorg voor herstel van je lichaam</li>
        </ul>

      </TextBanner>

      <CtaButtons />

      <Cards />
    </Layout>
  )
}

export default CoachPage
